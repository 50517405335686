<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <PageTransfer
    :role="role"
    :model="model"
    :url="nn"
    action="add"
    :self="self"
    :callbackSuccess="callbackSuccess"
  ></PageTransfer>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import model from '@/models/items/teacher'
  //EOC
  import PageTransfer from '@/components/Moderator/Teacher/PageTransfer'
  import { mapState } from 'vuex'
  export default {
    components:{
      PageTransfer,
    },
    computed: mapState({
      
    }),
    props:[
      "self"
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:'Transfer '+ this.$t("model.name." + this.model.name.singular.toLowerCase()),
        to:{name:'PageModeratorTeacherTransfer'},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        this.$router.push({name:'PageModeratorTeacherBrowse'})
      },
    }
  }
</script>