<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card class="mx-auto pa-3 mb-3">
        <v-card-title>Transfer Teacher</v-card-title>
        <v-card-text>
          <!-- <template>
            <Institution
              :callbackError="showError"
              title="Transfer From"
              :url="`${this.$api.servers.sso}/api/v1/zh/moderator/teacher/${this.$route.params.id}/institution/transferFrom`"
              @input="form.fromInstitutionId = $event"
            />
          </template> -->
          <template>
            <Institution
              :callbackError="showError"
              title="Transfer To"
              :url="`${this.$api.servers.sso}/api/v1/zh/moderator/teacher/${this.$route.params.id}/transferTo`"
              @input="form.institutionId = $event"
            />
          </template>
          <template>
            <Position
              :value="form.positionId"
              @input="form.positionId = $event"
            />
            </template>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="submit">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import Institution from './Institution.vue';
import Position from './Position.vue';
export default {
  components: {Institution, Position},
  computed: mapState({
    //
  }),
  props: ["self", "role", "model", "action", "callbackSuccess"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {
      institutionId:null,
      positionId: null
    },
  }),

  created() {
    //BOC
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/moderator/teacher/" +
      this.$route.params.id +
      "/transfer";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  mounted() {
    this.form.positionId = this.self.positionId
  },
  methods: {
    submit() {
        this.api.params = this.$_.clone(this.form)
        this.$api.fetch(this.api)
      },
  },
};
</script>