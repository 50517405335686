<template>
  <div>
  <!-- EOC -->
    <v-select
      :label="title"
      key="id"
      class="white"
      :items="data"
      item-text="name"
      item-value="id"
      v-model="inputVal"
      :loading="api.isLoading"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: [ "callbackError","url","title"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
    value: null
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      if(resp[0]){
        this.inputVal = resp[0].id;
        this.value = resp[0].id
      }
    };
    //EOC
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    // id: function (val) {
    //   this.id = val;
    //   this.item = this.$_.find(this.data, { id: this.id });
    // },
  },
  methods: {
    fetch() {
      this.api.url = this.url
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
